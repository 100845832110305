<template>
  <div>
    <div v-if="!show_step">
      <!-- ------------------------------PASO 1------------------------------ -->
      <section v-if="step == 1">
        <div class="step-grid mb-5">
          <div class="step-icon-border first-load-border mr-5">
            <b-icon-list-ul
              id="step1"
              class="step-icon-info first-load-icon"
            ></b-icon-list-ul>
            <p class="step-caption text-success">
              Paso 1: Detalles de la
              {{
                $getVisibleNames("evaluations2.evaluation", false, "Evaluación")
              }}
            </p>
          </div>
          <div class="step-icon-border mx-5">
            <b-icon-people-fill class="step-icon"></b-icon-people-fill>
            <p class="step-caption">Paso 2: Asignar Evaluado(s)</p>
          </div>
          <div class="step-icon-border ml-5">
            <b-icon-person-check-fill
              class="step-icon"
            ></b-icon-person-check-fill>
            <p class="step-caption">Paso 3: Asignar Evaluador(es)</p>
          </div>
        </div>
        <span class="progress first-load"></span>

        <div class="container-group">
          <!-- Nombre Evaluación -->
          <b-form-group
            :label="`Nombre de la ${$getVisibleNames(
              'evaluations2.evaluation',
              false,
              'Evaluación'
            )}`"
            label-for="input-name"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <b-form-input
              id="input-name"
              v-model="$v.evaluation.title.$model"
              :state="validateStateEvaluation('title')"
              aria-describedby="input-name-feedback"
              size="sm"
            ></b-form-input>
            <b-form-invalid-feedback id="input-name-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>

          <!-- Descripción -->
          <b-form-group
            :label="`Descripción de la ${$getVisibleNames(
              'evaluations2.evaluation',
              false,
              'Evaluación'
            )}`"
            label-for="input-description"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <div
              v-b-tooltip.v-secondary="
                `Editar Descripción de la ${$getVisibleNames(
                  'evaluations2.evaluation',
                  false,
                  'Evaluación'
                )}`
              "
              class="evaluation-form-description rich-text-content mb-2"
              v-html="$v.evaluation.description.$model"
              @click="
                $bvModal.show(
                  `edit-evaluation-description-modal-${evaluation.id}`
                )
              "
            ></div>
            <b-modal
              :id="`edit-evaluation-description-modal-${evaluation.id}`"
              :title="`Editar Descripción de la ${$getVisibleNames(
                'evaluations2.evaluation',
                false,
                'Evaluación'
              )}`"
              size="lg"
              hide-footer
            >
              <NewRichTextEditor
                :Object="evaluation"
                :Text="evaluation.description"
                @save="patchEvaluation"
                @close="
                  $bvModal.hide(
                    `edit-evaluation-description-modal-${evaluation.id}`
                  )
                "
              ></NewRichTextEditor>
            </b-modal>
            <b-form-invalid-feedback id="input-description-feedback"
              >Este campo es opcional.</b-form-invalid-feedback
            >
          </b-form-group>

          <!-- Tipo  -->
          <b-form-group
            label="Tipo de Evaluación"
            label-for="select-type"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <b-form-select
              id="select-type"
              value-field="id"
              text-field="value"
              v-model="$v.evaluation_type.type.$model"
              :state="validateStateType('type')"
              :options="evaluation_types"
              @input="changeEvaluationType()"
              aria-describedby="select-type-feedback"
              size="sm"
            ></b-form-select>
            <b-form-invalid-feedback id="select-type-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>
          <!-- Peso -->
          <b-form-group
            v-if="
              evaluation_type.type == 1 ||
              (institution &&
                institution.internal_use_id != 'duoc_uc' &&
                evaluation_type.type == 4)
            "
            :label="`Ponderación de la ${$getVisibleNames(
              'evaluations2.evaluation',
              false,
              'Evaluación'
            )}`"
            label-for="input-name"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <b-form-input
              id="input-name"
              v-model="$v.evaluation.weighing.$model"
              :state="validateStateEvaluation('weighing')"
              type="number"
              aria-describedby="input-name-feedback"
              size="sm"
            ></b-form-input>
            <b-form-invalid-feedback id="input-name-feedback"
              >Este campo es obligatorio y debe ser mayor a
              0.</b-form-invalid-feedback
            >
          </b-form-group>

          <!-- Categoria -->
          <b-form-group
            v-if="
              !(
                (institution && institution.internal_use_id == 'duoc_uc') ||
                $debug_change_duoc
              )
            "
            label="Categoría"
            label-for="select-type"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <b-form-select
              id="select-type"
              value-field="id"
              text-field="value"
              v-model="$v.evaluation_category.category.$model"
              :state="validateStateCategory('category')"
              :options="evaluation_categories"
              aria-describedby="select-type-feedback"
              size="sm"
            ></b-form-select>
            <b-form-invalid-feedback id="select-type-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>
          <!-- Scoped -->
          <b-form-group
            v-if="[1, 2, 3, 4, 5, 6, 7].includes(evaluation_scoped_id)"
            :label="scoped_name"
            label-for="select-scoped"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <v-select
              id="select-scoped"
              :options="optionsScoped"
              v-model="$v.evaluation_scoped.scope_id.$model"
              :reduce="(optionsScoped) => optionsScoped.id"
              :state="validateStateScope('scope_id')"
              :placeholder="`${
                optionsScoped.length > 0
                  ? String('Seleccione una ' + scoped_name)
                  : String('No cuenta con ' + scoped_name + ' creadas')
              }`"
              label="name"
              track-by="id"
              size="sm"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  No se encontró resultados para <em>{{ search }}</em
                  >.
                </template>
              </template>
            </v-select>
            <div
              class="div-invalid-feedback"
              v-if="$v.evaluation_scoped.scope_id.$anyError == true"
            >
              Este campo es obligatorio.
            </div>
          </b-form-group>
          <!-- Bloquear crear Instrumento -->
          <b-form-group
            v-if="user && !user.groups.includes(6)"
            :label="`Bloquear creación de ${$getVisibleNames(
              'evaluations2.test',
              false,
              'Instrumento'
            )}`"
            label-for="input-test_creation_is_locked"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <b-form-checkbox
              class="mt-2"
              v-model="evaluation.test_creation_is_locked"
              size="md"
            >
            </b-form-checkbox>
          </b-form-group>
          <!-- Archivos -->
          <b-form-group
            label="Archivos"
            label-for="file-attachment"
            label-cols="0"
            label-cols-sm="3"
            class="m-0 p-0 mb-2"
          >
            <template v-if="evaluation.files.length > 0">
              <FileContainer
                :Files="evaluation.files"
                :allows_crud="true"
                @deleteFile="slotDeleteFile"
              ></FileContainer>
            </template>
            <b-button
              @click="$bvModal.show('modal-select-file')"
              size="sm"
              class="open-file-selector mt-1"
              >Seleccionar Archivo</b-button
            >
            <b-modal
              id="modal-select-file"
              title="Seleccionar Archivos"
              hide-footer
            >
              <FileSelector @created="slotCreatedFile"></FileSelector>
            </b-modal>
          </b-form-group>
        </div>
      </section>

      <!-- ------------------------------PASO 2------------------------------ -->
      <div class="hidden-step" :class="{ 'is-shown': this.step == 2 }">
        <div class="step-grid mb-5">
          <div class="step-icon-border second-load-border-step1 mr-5 done">
            <b-icon-list-ul
              id="step1"
              class="step-icon second-load-icon-step1"
              variant="success"
            ></b-icon-list-ul>
            <p class="step-caption text-success">
              Paso 1: Detalles de la
              {{
                $getVisibleNames("evaluations2.evaluation", false, "Evaluación")
              }}
              <b-icon-check2-circle variant="success"></b-icon-check2-circle>
            </p>
          </div>
          <div class="step-icon-border second-load-border mx-5">
            <b-icon-people-fill
              class="step-icon-info second-load-icon"
            ></b-icon-people-fill>
            <p class="step-caption text-success">Paso 2: Asignar Evaluado(s)</p>
          </div>
          <div class="step-icon-border ml-5">
            <b-icon-person-check-fill
              class="step-icon"
            ></b-icon-person-check-fill>
            <p class="step-caption">Paso 3: Asignar Evaluador(es)</p>
          </div>
        </div>
        <div class="progress second-load done"></div>
        <div class="container-group">
          <!-- Acá va el contenido -->
          <NewUserList
            :key="'evaluatees:' + evaluation_users.id"
            :key_component="'evaluatees:' + evaluation_users.id"
            :users_ids="evaluatees"
            :allows_crud="!ClosedEvaluatee"
            :pre_multiple_search="['Estudiante']"
            @selected_user="slotSelectedEvaluatees"
          ></NewUserList>
        </div>
      </div>

      <!-- ------------------------------PASO 3------------------------------ -->
      <div class="hidden-step" :class="{ 'is-shown': this.step == 3 }">
        <div class="step-grid mb-5">
          <div class="step-icon-border third-load-border-step1 mr-5 done">
            <b-icon-list-ul
              id="step1"
              class="step-icon third-load-icon-step1"
              variant="success"
            ></b-icon-list-ul>
            <p class="step-caption text-success">
              Paso 1: Detalles de la
              {{
                $getVisibleNames("evaluations2.evaluation", false, "Evaluación")
              }}
              <b-icon-check2-circle variant="success"></b-icon-check2-circle>
            </p>
          </div>
          <div class="step-icon-border third-load-border-step2 mx-5 done">
            <b-icon-people-fill
              class="step-icon third-load-icon-step2"
              variant="success"
            ></b-icon-people-fill>
            <p class="step-caption text-success">
              Paso 2: Asignar Evaluado(s)
              <b-icon-check2-circle variant="success"></b-icon-check2-circle>
            </p>
          </div>
          <div
            class="step-icon-border third-load-border ml-5"
            :class="[{ done: saved }, { info: !saved }]"
          >
            <b-icon-person-check-fill
              class="step-icon third-load-icon"
              :style="[
                saved
                  ? { color: 'white !important' }
                  : { color: 'var(--secondary-color)' },
              ]"
            ></b-icon-person-check-fill>
            <p
              class="step-caption"
              :class="[{ 'text-success': saved }, { 'text-success': !saved }]"
            >
              Paso 3: Asignar Evaluador(es)<b-icon-check2-circle
                :style="[saved ? { display: 'inline' } : { display: 'none' }]"
                variant="success"
              ></b-icon-check2-circle>
            </p>
          </div>
        </div>
        <span
          class="progress third-load done"
          :class="[{ fill: saved }]"
        ></span>
        <div class="container-group">
          <!-- Acá va el contenido -->
          <NewUserList
            :key="'evaluatees:' + evaluation_users.id"
            :key_component="'evaluators:' + evaluation_users.id"
            :users_ids="evaluators"
            :pre_filter_array="['groups__id__exclude__in=7']"
            @selected_user="slotSelectedEvaluators"
          ></NewUserList>
        </div>
      </div>
    </div>
    <div v-else-if="show_step">
      <section>
        <!-- Nombre Evaluación -->
        <b-form-group
          :label="`Nombre de la ${$getVisibleNames(
            'evaluations2.evaluation',
            false,
            'Evaluación'
          )}`"
          label-for="input-name"
          label-cols="0"
          label-cols-sm="3"
          label-cols-md="3"
          class="m-0 p-0"
        >
          <template #label>
            <div class="container-info-tooltip">
              <span
                >Nombre de la
                {{
                  $getVisibleNames(
                    "evaluations2.evaluation",
                    false,
                    "Evaluación"
                  )
                }}</span
              >
              <InfoTooltip
                v-if="
                  evaluation.matter_evaluation != null ||
                  evaluation.egress_profile_matter_evaluation != null
                "
                class="ml-1 mt-1"
                :tooltip_text="`Los campos de este formulario se encuentran deshablitidados y para poder editarlos diríjase al ${$getVisibleNames(
                  'manual.matter_program',
                  false,
                  'Programa de Asignatura'
                )}.`"
              >
              </InfoTooltip>
            </div>
          </template>
          <b-form-input
            id="input-name"
            v-model="$v.evaluation.title.$model"
            :disabled="
              evaluation.matter_evaluation != null ||
              evaluation.egress_profile_matter_evaluation != null
            "
            :state="validateStateEvaluation('title')"
            aria-describedby="input-name-feedback"
            size="sm"
          ></b-form-input>
          <b-form-invalid-feedback id="input-name-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>

        <!-- Descripción -->
        <b-form-group
          :label="`Descripción de la ${$getVisibleNames(
            'evaluations2.evaluation',
            false,
            'Evaluación'
          )}`"
          label-for="input-description"
          label-cols="0"
          label-cols-sm="3"
          label-cols-md="3"
          class="m-0 p-0"
        >
          <div
            :class="{
              'disabled-input':
                evaluation.matter_evaluation != null ||
                evaluation.egress_profile_matter_evaluation != null,
            }"
            v-b-tooltip.v-secondary="
              evaluation.matter_evaluation == null &&
              evaluation.egress_profile_matter_evaluation == null
                ? 'Editar Descripción de la ' +
                  $getVisibleNames(
                    'evaluations2.evaluation',
                    false,
                    'Evaluación'
                  )
                : ''
            "
            class="evaluation-form-description rich-text-content mb-2"
            v-html="$v.evaluation.description.$model"
            @click="
              if (
                evaluation.matter_evaluation == null &&
                evaluation.egress_profile_matter_evaluation == null
              )
                $bvModal.show(
                  `edit-evaluation-description-modal-${evaluation.id}`
                );
            "
          ></div>
          <b-modal
            :id="`edit-evaluation-description-modal-${evaluation.id}`"
            :title="`Editar Descripción de la ${$getVisibleNames(
              'evaluations2.evaluation',
              false,
              'Evaluación'
            )}`"
            size="lg"
            hide-footer
          >
            <NewRichTextEditor
              :Object="evaluation"
              :Text="evaluation.description"
              @save="patchEvaluation"
              @close="
                $bvModal.hide(
                  `edit-evaluation-description-modal-${evaluation.id}`
                )
              "
            ></NewRichTextEditor>
          </b-modal>
          <b-form-invalid-feedback id="input-description-feedback"
            >Este campo es opcional.</b-form-invalid-feedback
          >
        </b-form-group>

        <!-- Tipo  -->
        <b-form-group
          label="Tipo de Evaluación"
          label-for="select-type"
          label-cols="0"
          label-cols-sm="3"
          label-cols-md="3"
          class="m-0 p-0"
        >
          <b-form-select
            id="select-type"
            value-field="id"
            text-field="value"
            v-model="$v.evaluation_type.type.$model"
            :disabled="
              evaluation.matter_evaluation != null ||
              evaluation.egress_profile_matter_evaluation != null
            "
            :state="validateStateType('type')"
            :options="evaluation_types"
            @input="changeEvaluationType()"
            aria-describedby="select-type-feedback"
            size="sm"
          ></b-form-select>
          <b-form-invalid-feedback id="select-type-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>
        <!-- Peso -->
        <b-form-group
          v-if="
            evaluation_type.type == 1 ||
            (institution &&
              institution.internal_use_id != 'duoc_uc' &&
              evaluation_type.type == 4)
          "
          :label="`Ponderación de la ${$getVisibleNames(
            'evaluations2.evaluation',
            false,
            'Evaluación'
          )}`"
          label-for="input-name"
          label-cols="0"
          label-cols-sm="3"
          label-cols-md="3"
          class="m-0 p-0"
        >
          <b-form-input
            id="input-name"
            v-model="$v.evaluation.weighing.$model"
            :disabled="
              evaluation.matter_evaluation != null ||
              evaluation.egress_profile_matter_evaluation != null
            "
            :state="validateStateEvaluation('weighing')"
            type="number"
            aria-describedby="input-name-feedback"
            size="sm"
          ></b-form-input>
          <b-form-invalid-feedback id="input-name-feedback">
            <template>
              <span v-if="evaluation.weighing <= 0"
                >Debe ser mayor que 0 y
              </span>
              <span v-if="evaluation.weighing == ''">
                Este campo es obligatorio y</span
              >
              No debe exceder a
              {{
                maxWeighing >= 0
                  ? maxWeighing
                  : maxWeighing +
                    parseFloat(
                      evaluation.weighing >= 0 && evaluation.weighing != ""
                        ? evaluation.weighing
                        : 0
                    )
              }}%
            </template>
          </b-form-invalid-feedback>
        </b-form-group>

        <!-- Categoria -->
        <b-form-group
          v-if="
            !(
              (institution && institution.internal_use_id == 'duoc_uc') ||
              $debug_change_duoc
            )
          "
          label="Categoría"
          label-for="select-type"
          label-cols="0"
          label-cols-sm="3"
          label-cols-md="3"
          class="m-0 p-0"
        >
          <b-form-select
            id="select-type"
            value-field="id"
            text-field="value"
            v-model="$v.evaluation_category.category.$model"
            :state="validateStateCategory('category')"
            :options="evaluation_categories"
            aria-describedby="select-type-feedback"
            size="sm"
          ></b-form-select>
          <b-form-invalid-feedback id="select-type-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>
        <!-- Scoped -->
        <b-form-group
          v-if="
            [1, 2, 3, 4, 5, 6, 7].includes(evaluation_scoped_id) &&
            evaluation.matter_evaluation == null &&
            evaluation.egress_profile_matter_evaluation == null &&
            isNaN(evaluation.id) &&
            !evaluation_scoped_object_id
          "
          :label="scoped_name"
          label-for="select-scoped"
          label-cols="0"
          label-cols-sm="3"
          label-cols-md="3"
          class="m-0 p-0"
        >
          <v-select
            id="select-scoped"
            :options="optionsScoped"
            v-model="$v.evaluation_scoped.scope_id.$model"
            :reduce="(optionsScoped) => optionsScoped.id"
            :state="validateStateScope('scope_id')"
            :placeholder="`${
              optionsScoped.length > 0
                ? String('Seleccione una ' + scoped_name)
                : String('No cuenta con ' + scoped_name + ' creadas')
            }`"
            label="name"
            track-by="id"
            size="sm"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                No se encontró resultados para <em>{{ search }}</em
                >.
              </template>
            </template>
          </v-select>
          <div
            class="div-invalid-feedback"
            v-if="$v.evaluation_scoped.scope_id.$anyError == true"
          >
            Este campo es obligatorio.
          </div>
        </b-form-group>
        <!-- Bloquear crear Instrumento -->
        <b-form-group
          v-if="user && !user.groups.includes(6)"
          :label="`Bloquear creación de ${$getVisibleNames(
            'evaluations2.test',
            false,
            'Instrumento'
          )}`"
          label-for="input-test_creation_is_locked"
          label-cols="0"
          label-cols-sm="3"
          label-cols-md="3"
          class="m-0 p-0"
        >
          <b-form-checkbox
            class="mt-2"
            v-model="evaluation.test_creation_is_locked"
            size="md"
          >
          </b-form-checkbox>
        </b-form-group>
        <!-- Archivos -->
        <b-form-group
          label="Archivos"
          label-for="file-attachment"
          label-cols="0"
          label-cols-sm="3"
          class="m-0 p-0 mb-2"
        >
          <template v-if="evaluation.files.length > 0">
            <FileContainer
              :Files="evaluation.files"
              :allows_crud="true"
              @deleteFile="slotDeleteFile"
            ></FileContainer>
          </template>
          <b-button
            @click="$bvModal.show('modal-select-file')"
            size="sm"
            class="open-file-selector mt-1"
            >Seleccionar Archivo</b-button
          >
          <b-modal
            id="modal-select-file"
            title="Seleccionar Archivos"
            hide-footer
          >
            <FileSelector @created="slotCreatedFile"></FileSelector>
          </b-modal>
        </b-form-group>
      </section>
    </div>
    <div style="text-align: right">
      <b-button
        class="mr-1"
        size="sm"
        v-if="step != 1 && !show_step"
        @click.prevent="prevStep"
      >
        Paso anterior
      </b-button>
      <b-button
        class="mr-1"
        size="sm"
        v-if="step == 3 || show_step"
        @click="saveEvaluation"
        >Guardar</b-button
      >
      <b-button
        class="mr-1"
        size="sm"
        v-if="step != total_steps && !show_step"
        @click.prevent="nextStep"
      >
        Siguiente paso
      </b-button>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
  minValue,
  numeric,
  maxValue,
} from "vuelidate/lib/validators";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { generateUniqueId, toast } from "@/utils/utils";

export default {
  name: "NewEvaluationForm",
  mixins: [validationMixin],
  components: {
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
    FileSelector: () => import("@/components/reusable/FileSelector"),
    FileContainer: () => import("@/components/reusable/FileContainer"),
    NewUserList: () => import("@/components/reusable/NewUserList"),
    InfoTooltip: () => import("@/components/reusable/InfoTooltip"),
    vSelect,
  },
  props: {
    Evaluation: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          title: "",
          description: "",
          weighing: 0,
          evaluation_type_default: null,
          evaluation_category_default: null,
          evaluation_scope_default: null,
          evaluation_users_default: null,
          test_creation_is_locked: false,
          matter_evaluation: null,
          egress_profile_matter_evaluation: null,
          files: [],
        };
      },
    },
    evaluation_scoped_id: { type: Number, required: true },
    evaluation_scoped_object_id: { type: Number },
    Sections: { type: Array },
    Matters: { type: Array },
    EgressProfileMatters: { type: Array },
    show_step: {
      type: Boolean,
      default: false,
    },
    ClosedEvaluatee: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      step: 1,
      total_steps: 3,
      saved: false,
      evaluation: { ...this.Evaluation },
      evaluation_type: {
        id: generateUniqueId(),
        type: null,
        evaluation: this.Evaluation.id,
      },
      evaluation_category: {
        id: generateUniqueId(),
        category: null,
        evaluation: this.Evaluation.id,
      },
      evaluation_scoped: {
        id: generateUniqueId(),
        scope: this.evaluation_scoped_id,
        scope_id: null,
        evaluation: this.Evaluation.id,
      },
      evaluation_users: {
        id: generateUniqueId(),
        evaluators: [],
        evaluatees: [],
        evaluation: this.Evaluation.id,
      },
      evaluators: [],
      evaluatees: [],
    };
  },
  validations: {
    evaluation: {
      title: { required },
      description: {},
      weighing: {
        required,
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(100),
        isValid() {
          if (![1, 4].includes(this.evaluation_type.type)) return true;
          else if (this.evaluation.weighing > 0 && this.maxWeighing >= 0)
            return true;
          else return false;
        },
      },
    },
    evaluation_type: {
      type: { required },
    },
    evaluation_category: {
      category: {
        isValid(value) {
          if (
            (this.institution &&
              this.institution.internal_use_id == "duoc_uc") ||
            this.$debug_change_duoc
          )
            return true;
          else if (value != null) return true;
          else return false;
        },
      },
    },
    evaluation_scoped: {
      scope_id: {
        isValid(value) {
          if (
            [1, 2, 3, 4, 5, 6, 7].includes(this.evaluation_scoped_id) &&
            value != null
          )
            return true;
          else if (
            [1, 2, 3, 4, 5, 6, 7].includes(this.evaluation_scoped_id) &&
            value == null
          )
            return false;
          else return true;
        },
      },
    },
    evaluation_users: {},
    validationGroup: [
      "evaluation",
      "evaluation_type",
      "evaluation_category",
      "evaluation_scoped",
      "evaluation_users",
    ],
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      egress_profiles: names.EGRESS_PROFILES,
      careers: names.CAREERS,
      faculties: names.FACULTIES,
      campuses: names.CAMPUSES,
      evaluations: names.NEW_EVALUATIONS,
      evaluation_types: names.NEW_EVALUATION_TYPES,
      evaluation_categories: names.NEW_EVALUATION_CATEGORIES,
    }),
    scoped_name() {
      if (this.evaluation_scoped_id == 1)
        return this.$getVisibleNames("teaching.section", true, "Sección");
      else if (this.evaluation_scoped_id == 2)
        return this.$getVisibleNames(
          "mesh.egressprofilematter",
          true,
          "Asignatura"
        );
      else if (this.evaluation_scoped_id == 3)
        return this.$getVisibleNames(
          "teaching.matter",
          true,
          "Asignatura Base"
        );
      else if (this.evaluation_scoped_id == 4)
        return this.$getVisibleNames(
          "mesh.egressprofile",
          true,
          "Perfil De Egreso"
        );
      else if (this.evaluation_scoped_id == 5)
        return this.$getVisibleNames("mesh.career", true, "Programa");
      else if (this.evaluation_scoped_id == 6)
        return this.$getVisibleNames("mesh.faculty", true, "Facultad");
      else if (this.evaluation_scoped_id == 7)
        return this.$getVisibleNames("mesh.campus", true, "Sede");
      else return "";
    },
    optionsScoped() {
      if (this.evaluation_scoped_id == 1) return this.Sections;
      else if (this.evaluation_scoped_id == 2)
        return this.EgressProfileMatters.map((objeto) => ({
          ...objeto,
          name: objeto.matter_name,
        }));
      else if (this.evaluation_scoped_id == 3) return this.Matters;
      else if (this.evaluation_scoped_id == 4) return this.egress_profiles;
      else if (this.evaluation_scoped_id == 5) return this.careers;
      else if (this.evaluation_scoped_id == 6) return this.faculties;
      else if (this.evaluation_scoped_id == 7) return this.campuses;
      else return [];
    },
    evaluationFilter() {
      if (!this.evaluation_scoped_id || !this.evaluation_scoped_object_id)
        return [];
      return this.evaluations.filter(
        (x) =>
          x.scope == this.evaluation_scoped_id &&
          x.scope_id == this.evaluation_scoped_object_id
      );
    },
    maxWeighing() {
      let max_weighing = this.evaluationFilter.reduce((acc, x) => {
        if (
          (this.institution &&
            (this.institution.internal_use_id == "duoc_uc" ||
              this.institution.apply_transversal_exam_calculation)) ||
          this.$debug_change_duoc
        ) {
          if (this.evaluation.id != x.id && x.type == this.evaluation_type.type)
            return x.id != this.evaluation.id
              ? acc + parseFloat(x.weighing)
              : acc;
        } else {
          return x.id != this.evaluation.id
            ? acc + parseFloat(x.weighing)
            : acc;
        }
        return acc;
      }, 0);
      if (
        this.evaluation.weighing != "" &&
        parseFloat(this.evaluation.weighing) >= 0
      ) {
        max_weighing += parseFloat(this.evaluation.weighing);
      }
      if (
        (this.institution && this.institution.internal_use_id == "duoc_uc") ||
        this.$debug_change_duoc
      ) {
        if (this.evaluation_type.type == 4) return 40 - max_weighing;
      }
      return 100 - max_weighing;
    },
  },
  methods: {
    prevStep() {
      return this.step--;
    },
    nextStep() {
      this.$v.validationGroup.$touch();
      if (this.$v.validationGroup.$anyError) {
        return;
      }
      return this.step++;
    },
    validateStateEvaluation(name) {
      const { $dirty, $error } = this.$v.evaluation[name];
      return $dirty ? !$error : null;
    },
    validateStateType(name) {
      const { $dirty, $error } = this.$v.evaluation_type[name];
      return $dirty ? !$error : null;
    },
    validateStateCategory(name) {
      const { $dirty, $error } = this.$v.evaluation_category[name];
      return $dirty ? !$error : null;
    },
    validateStateScope(name) {
      const { $dirty, $error } = this.$v.evaluation_scoped[name];
      return $dirty ? !$error : null;
    },
    slotSelectedEvaluatees(users) {
      this.evaluation_users.evaluatees = users.map((x) => x.id);
    },
    slotSelectedEvaluators(users) {
      this.evaluation_users.evaluators = users.map((x) => x.id);
    },
    changeEvaluationType() {
      if (this.evaluation_type.type != 1 && this.evaluation_type.type != 4)
        this.evaluation.weighing = 0;
    },
    patchEvaluation(object, text) {
      this.evaluation.description = text;
    },
    fetchEvaluationsInfo() {
      if (this.evaluation.evaluation_type_default != null) {
        this.$restful
          .Get(
            `/evaluations2/evaluation-type-default/${this.evaluation.evaluation_type_default}/`
          )
          .then((response) => {
            this.evaluation_type = response;
          });
      }
      if (this.evaluation.evaluation_category_default != null) {
        this.$restful
          .Get(
            `/evaluations2/evaluation-category-default/${this.evaluation.evaluation_category_default}/`
          )
          .then((response) => {
            this.evaluation_category = response;
          });
      }
      if (this.evaluation.evaluation_scope_default != null) {
        this.$restful
          .Get(
            `/evaluations2/evaluation-scope-default/${this.evaluation.evaluation_scope_default}/`
          )
          .then((response) => {
            this.evaluation_scoped = response;
          });
      }
      if (this.evaluation.evaluation_users_default != null) {
        this.$restful
          .Get(
            `/evaluations2/evaluation-users-default/${this.evaluation.evaluation_users_default}/`
          )
          .then((response) => {
            this.evaluation_users = response;
            this.evaluators = response.evaluators;
            this.evaluatees = response.evaluatees;
          });
      }
    },
    slotCreatedFile(file) {
      if (file) {
        this.evaluation.files.push(file.id);
        if (!isNaN(this.evaluation.id)) {
          this.$store.dispatch(names.PATCH_NEW_EVALUATION, {
            new_evaluation_id: this.evaluation.id,
            item: {
              files: this.evaluation.files,
            },
          });
        }
      }
      this.$bvModal.hide("modal-select-file");
    },
    slotDeleteFile(file_id) {
      const index = this.evaluation.files.indexOf(file_id);
      if (index != -1) this.evaluation.files.splice(index, 1);
      const index2 = this.Evaluation.files.indexOf(file_id);
      if (index2 != -1) this.Evaluation.files.splice(index, 1);
    },
    saveEvaluation() {
      this.$v.validationGroup.$touch();
      if (this.$v.validationGroup.$anyError) {
        return;
      }
      this.saved = true;
      if (isNaN(this.evaluation.id)) this.createEvaluation();
      else this.updateEvaluation();
    },
    createEvaluation() {
      this.$store
        .dispatch(names.POST_NEW_EVALUATION, this.evaluation)
        .then((response) => {
          this.evaluation = response;
          this.evaluation_type.evaluation = response.id;
          this.evaluation_category.evaluation = response.id;
          this.evaluation_scoped.evaluation = response.id;
          this.evaluation_users.evaluation = response.id;
          toast(
            "Se creó la " +
              this.$getVisibleNames(
                "evaluations2.evaluation",
                false,
                "Evaluación"
              )
          );
          if (response.evaluation_type_default == null)
            this.createEvaluationType();
          else this.updateEvaluationType();
        });
    },
    updateEvaluation() {
      this.$store
        .dispatch(names.UPDATE_NEW_EVALUATION, this.evaluation)
        .then((response) => {
          this.evaluation = response;
          this.evaluation_type.evaluation = response.id;
          this.evaluation_category.evaluation = response.id;
          this.evaluation_scoped.evaluation = response.id;
          this.evaluation_users.evaluation = response.id;
          toast(
            this.$getVisibleNames(
              "evaluations2.evaluation",
              false,
              "Evaluación"
            ) + " editada correctamente"
          );
          if (this.evaluation.evaluation_type_default == null)
            this.createEvaluationType();
          else this.updateEvaluationType();
        });
    },
    createEvaluationType() {
      this.$restful
        .Post("/evaluations2/evaluation-type-default/", this.evaluation_type)
        .then((response) => {
          this.$store.commit(
            "MUTATE_CHANGE_NEW_EVALUATION_TYPE_BY_NEW_EVALUATION",
            response
          );
          this.evaluation_type = response;
          if (
            (this.institution &&
              this.institution.internal_use_id == "duoc_uc") ||
            this.$debug_change_duoc
          ) {
            if (this.evaluation.evaluation_scope_default == null)
              this.createEvaluationScope();
            else this.updateEvaluationScope();
          } else {
            if (this.evaluation.evaluation_category_default == null)
              this.createEvaluationCategory();
            else this.updateEvaluationCategory();
          }
        });
    },
    updateEvaluationType() {
      this.$restful
        .Put(
          `/evaluations2/evaluation-type-default/${this.evaluation_type.id}/`,
          this.evaluation_type
        )
        .then((response) => {
          this.$store.commit(
            "MUTATE_CHANGE_NEW_EVALUATION_TYPE_BY_NEW_EVALUATION",
            response
          );
          this.evaluation_type = response;
          if (
            (this.institution &&
              this.institution.internal_use_id == "duoc_uc") ||
            this.$debug_change_duoc
          ) {
            if (this.evaluation.evaluation_scope_default == null)
              this.createEvaluationScope();
            else this.updateEvaluationScope();
          } else {
            if (this.evaluation.evaluation_category_default == null)
              this.createEvaluationCategory();
            else this.updateEvaluationCategory();
          }
        });
    },
    createEvaluationCategory() {
      this.$restful
        .Post(
          "/evaluations2/evaluation-category-default/",
          this.evaluation_category
        )
        .then((response) => {
          this.$store.commit(
            "MUTATE_CHANGE_NEW_EVALUATION_CATEGORY_BY_NEW_EVALUATION",
            response
          );
          this.evaluation_category = response;
          if (this.evaluation.evaluation_scope_default == null)
            this.createEvaluationScope();
          else this.updateEvaluationScope();
        });
    },
    updateEvaluationCategory() {
      this.$restful
        .Put(
          `/evaluations2/evaluation-category-default/${this.evaluation_category.id}/`,
          this.evaluation_category
        )
        .then((response) => {
          this.$store.commit(
            "MUTATE_CHANGE_NEW_EVALUATION_CATEGORY_BY_NEW_EVALUATION",
            response
          );
          this.evaluation_category = response;
          if (this.evaluation.evaluation_scope_default == null)
            this.createEvaluationScope();
          else this.updateEvaluationScope();
        });
    },
    createEvaluationScope() {
      this.$restful
        .Post("/evaluations2/evaluation-scope-default/", this.evaluation_scoped)
        .then((response) => {
          this.$store.commit(
            "MUTATE_CHANGE_NEW_EVALUATION_SCOPE_BY_NEW_EVALUATION",
            response
          );
          this.evaluation_scoped = response;
          if (this.evaluation.evaluation_users_default == null)
            this.createEvaluationUsers();
          else this.updateEvaluationUsers();
        });
    },
    updateEvaluationScope() {
      this.$restful
        .Put(
          `/evaluations2/evaluation-scope-default/${this.evaluation_scoped.id}/`,
          this.evaluation_scoped
        )
        .then((response) => {
          this.$store.commit(
            "MUTATE_CHANGE_NEW_EVALUATION_SCOPE_BY_NEW_EVALUATION",
            response
          );
          this.evaluation_scoped = response;
          if (this.evaluation.evaluation_users_default == null)
            this.createEvaluationUsers();
          else this.updateEvaluationUsers();
        });
    },
    createEvaluationUsers() {
      this.$restful
        .Post("/evaluations2/evaluation-users-default/", this.evaluation_users)
        .then((response) => {
          this.$store.commit(
            "MUTATE_CHANGE_NEW_EVALUATION_USERS_BY_NEW_EVALUATION",
            response
          );
          this.evaluation_users = response;
          this.$emit("closeModal", this.evaluation.id);
        });
    },
    updateEvaluationUsers() {
      this.$restful
        .Put(
          `/evaluations2/evaluation-users-default/${this.evaluation_users.id}/`,
          this.evaluation_users
        )
        .then((response) => {
          this.$store.commit(
            "MUTATE_CHANGE_NEW_EVALUATION_USERS_BY_NEW_EVALUATION",
            response
          );
          this.evaluation_users = response;
          this.$emit("closeModal", this.evaluation.id);
        });
    },
  },
  created() {
    if (
      (this.evaluation_scoped_object_id != null && isNaN(this.evaluation)) ||
      this.evaluation_scoped.scope_id == null
    ) {
      this.evaluation_scoped.scope_id = this.evaluation_scoped_object_id;
    }
    this.$store.dispatch(names.FETCH_NEW_EVALUATION_CATEGORIES);
    this.$store.dispatch(names.FETCH_ROLES);
    this.fetchEvaluationsInfo();
  },
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>

<style scoped>
.container-info-tooltip {
  display: flex;
  justify-content: space-between;
}
.step-grid {
  display: flex;
  justify-content: center;
  margin-bottom: 10% !important;
}
.open-file-selector {
  width: 100%;
}
.step-icon-border {
  width: 50px;
  height: 50px;
  border: 3px solid var(--secondary-color);
  border-radius: 50%;
  padding: 11px 1px 1px 12px;
  z-index: 30;
  background: #fff;
}
.disabled-input {
  color: #6c757d !important;
  background-color: #e9ecef !important;
}
.first-load-border {
  background: white;
  border: 3px solid #28a745;
}
.second-load-border {
  border: 3px solid #28a745;
  background: white;
}
.third-load-border {
  background: white;
}
.second-load-border-step1 {
  background: #28a745;
}
.third-load-border-step1 {
  background: #28a745;
}
.third-load-border-step2 {
  background: #28a745;
}
.first-load-icon {
  color: var(--secondary-color);
}
.second-load-icon {
  color: var(--secondary-color);
}
.third-load-icon {
  color: var(--secondary-color);
}
.second-load-icon-step1 {
  color: white !important;
}
.third-load-icon-step1 {
  color: white !important;
}
.third-load-icon-step2 {
  color: white !important;
}
.step-icon {
  font-size: 22px;
  color: var(--secondary-color);
}
.step-icon-info {
  font-size: 22px;
}
.step-caption {
  width: 170px;
  text-align: center;
  margin-top: 1.3em;
  transform: translateX(-42%);
}
.progress {
  border: 1px solid var(--secondary-color);
  height: 10px;
  margin-top: -12.9%;
  margin-bottom: 5%;
}
.first-load {
  border: 3px solid #28a745;
  background: #28a745;
  width: 35%;
  margin-right: 20%;
}
.second-load {
  border: 3px solid #28a745;
  background: #28a745;
  width: 50%;
  margin-right: 20%;
}
.third-load {
  border: 3px solid #28a745;
  background: #28a745;
  width: 65%;
  margin-right: 20%;
}
.fill {
  width: 100%;
}
.container-group {
  margin-top: 12%;
}
.hidden-step {
  display: none;
}
.is-shown {
  display: block;
}
.done {
  border: 2px solid #28a745 !important;
  background: #28a745;
  transition: 0.4s;
}
.info {
  border: 3px solid #28a745 !important;
  background: white;
  transition: 0.4s;
}
.evaluation-form-description {
  width: calc(100%);
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.875rem;
  min-height: calc(1.5em + 0.5rem + 2px);
  height: auto;
  max-height: 200px;
  overflow: auto;
  padding: 0.25rem 1.2rem;
  line-height: 1.5;
}
.evaluation-form-description >>> p {
  margin-bottom: 0.7rem !important;
}
.div-invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
</style>
